<template>
  <div>
    <!-- Banner Section -->
     <section class="banner d-flex align-items-center justify-content-center cleartx">
        <div class="container-fluid">
            <img src="../assets/img/LifeatArete/homesection.jpg" class="img" alt="Life at Arete">
            <div class="container csrbanner">
                <h2 class="csrbannertitle" data-aos="fade-up">Life at Arete</h2>
            </div>
        </div>
    </section>

    <section class="cleartx mt-5 mt-md-0">
        <div class="container">
            <div class="row">
                <div class="col-sm-12">
                    <h3 class="text-uppercase title indexheader breadcrumbs maintitle" data-aos="fade-up">/  LIFE AT ARETE<span class="green-dot"></span> </h3>
                    <br>
                    <p class="text-left lifeAreteDescription" data-aos="fade-up">The Arete Advantage serves as our beacon, guiding us to create unparalleled value in every pursuit we undertake. It ensures that our endeavors not only benefit businesses but also uplift individuals and communities at large.</p>
                </div>
            </div>
        </div>
        <div class="container mt-3">
            <div class="row align-items-stretch">
                <div class="col-sm-5 p-3 p-md-0 p-md-flex d-md-flex">
                    <div class="card w-100 h-100 custom-border-radius">
                        <div class="card-body px-5 py-0 d-flex flex-column justify-content-center">
                            <p class="card-title mb-3 lifeAretetitleSec" data-aos="fade-up">Our Culture: Empowering Greatness</p>
                            <p class="card-text mb-3 lifeDescription" data-aos="fade-up">At the heart of our organisation lies a culture centered on empowerment. We transcend boundaries to achieve personal and professional excellence by harnessing the collective wisdom of our team. Our shared values – excellence, empowerment, empathy, and resilience form the bedrock of our triumphs.</p>
                            <p class="card-text lifeDescription" data-aos="fade-up">We actively listen to and comprehend the challenges faced by our people and communities, leveraging our expertise to deliver holistic solutions. Our mission is to leave an indelible mark on the communities we are privileged to serve.</p>
                        </div>
                    </div>
                </div>
                
                <!-- Bootstrap Carousel for Image Slider -->
                <div class="col-sm-7">
                    <swiper :slidesPerView="1" 
                                    :spaceBetween="40"
                                    :centeredSlides="true"
                                    :autoplay="{
                                        delay: 2500,
                                        disableOnInteraction: false,
                                    }"
                                    :pagination="false"
                                    :navigation="false"
                                    :modules="modules"
                                    class="mySwiper"
                                    loop="true"
                                    :breakpoints="{
                                    640: {
                                        slidesPerView: 1,
                                    },
                                    768: {
                                        slidesPerView: 1,
                                    },
                                    1024: {
                                        slidesPerView: 1,
                                    }
                                    }" >
                                        <swiper-slide><img src="../assets/img/LifeatArete/1.webp" class="d-block w-100" alt="Slider 1" style="height: 454px;"></swiper-slide>
                                        <swiper-slide><img src="../assets/img/LifeatArete/2.webp" class="d-block w-100" alt="Slider 2" style="height: 454px;"></swiper-slide>
                                        <swiper-slide><img src="../assets/img/LifeatArete/3.webp" class="d-block w-100" alt="Slider 3" style="height: 454px;"></swiper-slide>
                                        <swiper-slide><img src="../assets/img/LifeatArete/4.webp" class="d-block w-100" alt="Slider 3" style="height: 454px;"></swiper-slide>
                                        
                                    </swiper>

                    

                </div>
            </div>
        </div>
    </section>
    
    <section class="cleartx">
            <div class="container p-5 bgLifeAreteLine">
                <div class="row  p-md-0 g-0">
                    <div class="col-sm-6 px-0 px-md-5 col align-self-start mt-5">
                        <h2 class="emp-engagement-title" data-aos="fade-up">
                            Employee Engagement:<br>Fostering Unity<br>and Well-being
                        </h2>
                    </div>
                    <div class="col-sm-6 px-0 mb-4 bg  mt-5">
                        <p class="lifeAreteDescription" data-aos="fade-up">
                            We conduct periodic engagement activities that serve as catalysts in fostering unity and boosting morale of our employees. By providing opportunities for connection and appreciation, we are able to create a vibrant work environment, and cultivate a positive workplace culture.
                        </p>
                        <p class="lifeAreteDescription " data-aos="fade-up">
                            These strategic initiatives not only encourage teamwork and collaboration, but also help us in championing health and wellness, significantly enhancing the overall well-being of our team members. We have witnessed this comprehensive approach significantly mitigate stress levels and absenteeism, thereby nurturing a healthier, exceptionally motivated and content workforce.
                        </p>
                    </div>
                </div>
            </div>
    </section>
    
    
    <section class="">
        <div class="container mt-4">
            <div class="row">
                <div class="value-section p-2 p-md-0">
                    <div class="col-sm-8 mb-3">
                        <div class="title-section">
                            <h3 class="title learning-developement-title" data-aos="fade-up">Learning and Development: <br> Nurturing Growth and Excellence</h3>
                        </div>
                    </div>
                    <div class="right-content col-sm-4 ">
                        <div class="slant-line"></div>
                        <p class="description" data-aos="fade-up">The establishment of the learning and development practice at Arete was driven by three pivotal objectives:
                        </p>
                    </div>
                </div>
                <div class="row justify-content-left g-3 p-0 p-md-0">
                    <div class="col-md-4">
                        <div class="card life-at-arete-custom-card h-100 p-5">
                            <img class="life-at-arete-card-img-top text-left" width="80" height="80" data-aos="fade-up" src="../assets/svg/Life_At_Arete-Card-1.svg">
                            <div class="card-body p-0 mt-5 life-at-arete-card-text">
                                <p class="card-text vluesTitle text-left" data-aos="fade-up">Cultivating a culture of continuous learning</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="card life-at-arete-custom-card-1 h-100 p-5">
                            <img class="life-at-arete-card-img-top text-left" width="80" height="80" data-aos="fade-up" src="../assets/svg/Life_At_Arete-Card-2.svg">
                            <div class="card-body p-0 mt-5 life-at-arete-card-text">
                                <p class="card-text vluesTitle text-left" data-aos="fade-up">Strengthening our organisational culture</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="card life-at-arete-custom-card-2 h-100 p-5">
                            <img class="life-at-arete-card-img-top text-left" width="80" height="80" data-aos="fade-up" src="../assets/svg/Life_At_Arete-Card-3.svg">
                            <div class="card-body p-0 mt-5 life-at-arete-card-text">
                                <p class="card-text vluesTitle text-left" data-aos="fade-up">Enhancing business productivity</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="cleartx ">
        <div class="container">
            <div class="row gx-5">
                <div class="col-sm-6 mt-5">
                    <p class="life-at-arete-Embracing" data-aos="fade-up"> Embracing the 3E Framework, we firmly believe that experiential learning is paramount for employee growth. At Arete, we adhere to the 70-20-10 Learning and Development Model, where 70% of learning occurs through hands-on experience, 20% through exposure via networking and feedback, and 10% through structured classroom learning.
                    </p>
                    <br>
                    <div class="card-group  card-mobile">
                        <div class="card border-0 mt-3">
                            <div class="card-body p-0">
                                <h5 class="card-title home-second-section-title" data-aos="fade-up">70 %</h5>
                                <p class="card-text" data-aos="fade-up">hands-on experience</p>
                            </div>
                        </div>
                        <div class="slant-line1"></div>
                        <div class="card border-0 mx-4 my-0 mt-3">
                            <div class="card-body p-0">
                                <h5 class="card-title home-second-section-title" data-aos="fade-up">20 %</h5>
                                <p class="card-text" data-aos="fade-up">networking and feedback</p>
                            </div>
                        </div>
                        <div class="slant-line1"></div>
                        <div class="card border-0 mx-4 my-0 mt-3">
                            <div class="card-body p-0">
                                <h5 class="card-title home-second-section-title" data-aos="fade-up">10 %</h5>
                                <p class="card-text" data-aos="fade-up">structured classroom learning.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Bootstrap Carousel for Image Slider -->
                <div class="col-sm-6 p-3 p-md-0">

                    <swiper :slidesPerView="1" 
                                    :spaceBetween="40"
                                    :centeredSlides="true"
                                    :autoplay="{
                                        delay: 2500,
                                        disableOnInteraction: false,
                                    }"
                                    :pagination="false"
                                    :navigation="false"
                                    :modules="modules"
                                    class="mySwiper"
                                    loop="true"
                                    :breakpoints="{
                                    640: {
                                        slidesPerView: 1,
                                    },
                                    768: {
                                        slidesPerView: 1,
                                    },
                                    1024: {
                                        slidesPerView: 1,
                                    }
                                    }" >
                                        <swiper-slide><img src="../assets/img/LifeatArete/DownSlider-3.webp" class="d-block w-100" alt="Slider 1" style="height: 454px;"></swiper-slide>
                                        <swiper-slide><img src="../assets/img/LifeatArete/DownSlider-2.webp" class="d-block w-100" alt="Slider 2" style="height: 454px;"></swiper-slide>
                                        <swiper-slide><img src="../assets/img/LifeatArete/DownSlider-1.webp" class="d-block w-100" alt="Slider 3" style="height: 454px;"></swiper-slide>
                                        <swiper-slide><img src="../assets/img/LifeatArete/DownSlider-4.webp" class="d-block w-100" alt="Slider 3" style="height: 454px;"></swiper-slide>
                                        
                                    </swiper>

                   
                </div>
            </div>
        </div>
    </section>
    
    <!-- last section -->
     <section class="cleartx ">
        <div class="container">
            <div class="row p-0 d-flex flex-column-reverse-mobile">
                <div class="col-sm-5">
                    <img src="../assets/img/IndustrialPark/Vector.png" class="img-fluid" alt="" srcset="" data-aos="fade-up">
                </div>
                <div class="col-sm-7 mb-3">
                    <div>
                        <h2 class="lifeAreteLearning" data-aos="fade-down">A Culture of Learning and Improvement</h2>
                        <br>
                        <p class="life-arete-culture" data-aos="fade-up">We strive to foster and champion a culture of perpetual learning by creating an environment that ignites a thirst for knowledge amongst our team members.</p>
                        <p class="life-arete-culture mb-5" data-aos="fade-up">We develop engaging and impactful training programmes that are in alignment with the larger organisational objectives in order to ensure that Arete maintains a competitive edge through a workforce of skilled, empowered and motivated individuals.
                        </p>
                        <p data-aos="fade-up" class="life-arete-culture mb-5">Bringing India’s brightest minds to the global stage! Curious about life as a Areteans? Shoot your resume <a class="bgcolor" href="mailto:hiring@areteservices.in">hiring@areteservices.in</a>   to join our team!</p>
                        <router-link to="/contactus">
                                <a target="_blank" id="brand-link" class="view-website-btn1 mt-3" data-aos="fade-up" style="font-size: 16px; font-weight: 400; line-height: 24px; text-align: left; text-decoration: none;">Join Us</a>
                                </router-link>
                    </div>
                  </div>
            </div>
        </div>
     </section>
    
  </div>
</template>


<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
// Import Swiper styles
import 'swiper/css';

import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    return {
      modules: [Autoplay, Pagination, Navigation],
    };
  },
  data() {
    return {};
  },
  methods: {
    // Add your methods here
  },
};



</script>

<style scoped>



    .bgLifeAreteLine{
        background: #BFD730;
        /* background-image: url('/src/assets/img/values/linebg.png'); */
        background-image: url('/src/assets/img/values/linebg.webp');
        background-size: cover;   
        background-position: bottom; 
        background-repeat: no-repeat; 
    }

 
.life-at-arete-custom-card:hover,.life-at-arete-custom-card-1:hover,.life-at-arete-custom-card-2:hover {
    background-color: #BFD730;
}
 
.life-at-arete-custom-card:hover .life-at-arete-card-img-top {
    /* content: url('/src/svg/Life_At_Arete-Hover-Card-1.svg'); */
    content: url('/src/assets/svg/Life_At_Arete-Hover-Card-1.svg');
    transform: scale(1.3);
}
.life-at-arete-custom-card-1:hover .life-at-arete-card-img-top {
    content: url('/src/assets/svg/Life_At_Arete-Hover-Card-2.svg');
    transform: scale(1.3);
}
.life-at-arete-custom-card-2:hover .life-at-arete-card-img-top {
    content: url('/src/assets/svg/Life_At_Arete-Hover-Card-3.svg');
    transform: scale(1.3);
}

.life-at-arete-custom-card:hover .life-at-arete-card-text{
    transform: scale(1.1);
}
.life-at-arete-custom-card-1:hover .life-at-arete-card-text{
    transform: scale(1.1);
}
 
.life-at-arete-custom-card-2:hover .life-at-arete-card-text{
    transform: scale(1.1);
}
 


</style>