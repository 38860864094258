<template>
    <div>
    <!-- Banner Section -->
    <section class="banner d-flex align-items-center justify-content-center cleartx">
        <div class="container-fluid">
            <img src="../assets/img/Hospitality/homesection.webp" class="img" alt="csr">
            <div class="container csrbanner">
                <h2 class="csrbannertitle" data-aos="fade-up">Hospitality</h2>
            </div>
        </div>
    </section>



    <section class="mt-4 hospitalitybg">
        <div class="container">
            <div class="row">           
                <div class="col-sm-6 mt-5">
                    <br>
                    <h3 class="text-uppercase title indexheader breadcrumbs maintitle" data-aos="fade-up">/  Hospitality<span class="green-dot"></span> </h3>
                    <br>
                    <h2 class="aboutTitle" data-aos="fade-up">
                        Arete: Redefining  Luxury Hospitality </h2>
                </div>
                <div class="col-sm-5 p-2   mt-3 mt-md-5 mb-3 mb-md-5" data-aos="fade-up">
                    <p class="aboutdescription  mb-3 mb-md-5">At Arete, our Hospitality Division is strategically positioned to operate luxury business hotels under the esteemed Fortune Park Galaxy brand, a partnership established with ITC Hotels in 2000. </p>
                    <p class="aboutdescription">This collaboration harnesses ITC Hotels’ unparalleled expertise in high-end hospitality, enabling us to deliver exceptional accommodations, bespoke experiences, and superior service quality.</p>
                </div>

            </div>
        </div>
    </section>
 
    <section class="cleartx bganimationcsr">
            <div class="container ">
                <div class="row gx-5"> 
                    <div class="col-sm-6 ">
                        <img src="../assets/img/Hospitality/fortunepark.webp" class="img-fluid" data-aos="fade-left" alt="img-fluid" srcset="">
                    </div>
                    <div class="col-sm-6 col align-self-center mt-3 mt-md-0"  data-aos="fade-up">
                        <h2 class="hospitality-title-second">Fortune Park Galaxy <br>
                            Vapi, Gujarat</h2><br>
                        <p class="csrdescription">The Fortune Park Galaxy Vapi, located in Gujarat, exemplifies our commitment to excellence. This full-service business and leisure hotel is meticulously designed to cater to the needs of discerning corporate executives and luxury travelers alike.</p>
                    </div>
                </div>
            </div>
    </section>

    <section class="cleartx bganimationcsr">
        <div class="container-fluid ">
            <div class="container">
                <div class="row gx-3"> 
                   
                    <div class="col-sm-6 col align-self-center " data-aos="fade-up" >
                        <h2 class="hospitality-title-second">Fortune Park Galaxy Kevadia, Gujarat</h2><br>
                            <p class="csrdescription">Strategically located near the iconic Statue of Unity, Fortune Park Galaxy Kevadia, Gujarat, is the second property under the Fortune Park Galaxy brand. It is a full-service leisure hotel that delights guests with its warm hospitality.</p>
                    </div>
                        <div class="col-sm-1"></div>
                    <div class="col-sm-5 p-2" data-aos="fade-up">
                        <img src="../assets/img/Hospitality/kevdiyaFortunepark.webp" class="img-fluid" alt="img-fluid" srcset="">
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="cleartx">
        <div class="container mb-5">
            <div class="row">
                <h2 class="hospitalityTitle" data-aos="fade-up">Distinguished Boutique Restaurants:</h2>
            </div>
        </div>

        <div class="container d-flex align-items-stretch justify-content-center w-100">
            <div class="row w-100 mb-2">
                <!-- Text Box -->
                <div class="col-lg-7 d-flex align-items-center   Distinguishedbg p-4" data-aos="fade-up">
                    <div>
                        <h4 class="mb-4">Sam’s Alive Again</h4>
                        <p class="hospitalitydescription">Sam’s Alive Again is a premier Health Café dedicated to promoting holistic wellness through the cultivation of positive eating habits. Our menu boasts over a hundred handcrafted delicacies, each meticulously crafted based on proprietary recipes developed by our in-house nutritionist and health expert.</p>
                        <a href="https://samsaliveagain.com/" target="_blank" class="btn btn-outline-dark rounded-pill custom-badge-load px-5 py-2">Visit Website</a>

                    </div>
                </div>
                <!-- Image Column -->
                <div class="col-sm-5 p-4 d-flex align-items-stretch justify-content-center">
                    <img src="../assets/img/Hospitality/sams.png" class="img-fluid" data-aos="fade-up" alt="Media topic">
                </div>
            </div>
        </div>

        <div class="container d-flex align-items-stretch justify-content-center w-100">
            <div class="row w-100 mb-2 d-flex flex-column-reverse-mobile">
                <!-- Image Column -->
                <div class="col-sm-5 p-4 d-flex align-items-stretch justify-content-center">
                    <img src="../assets/img/Hospitality/woodlands.png" data-aos="fade-up" class="img-fluid" alt="Media topic">
                </div>
                <!-- Text Box -->
                <div class="col-lg-7 d-flex align-items-center   Distinguishedbg1 p-5" data-aos="fade-up">
                    <div>
                        <h4 class="mb-4">Woodlands Restaurant</h4>
                        <p class="hospitalitydescription1">For those seeking authentic culinary experiences, Woodlands Restaurant offers a diverse selection of both South Indian and North Indian cuisines, ensuring a delightful dining experience.</p>

                    </div>
                </div>
            </div>
        </div>
    </section>

     <!-- last section -->
     <section class="cleartx hospitality-last-section">
        <div class="container ">
            <div class="row ">
                <div class="col-md-8 d-flex align-items-center">
                    <div class="industrialHeading">
                        <h2 class="hospitality-title-last text-white" data-aos="fade-up">The Leading Light in Hospitality</h2>
                        <br>
                        <p class="hospitalitydescri" data-aos="fade-up">Through these initiatives, we continue to solidify our position as a leader in the luxury hospitality sector, committed to delivering excellence at every touchpoint.</p>
                    </div>
                  </div>
                  <div class="col-sm-4  d-flex justify-content-end p-2" data-aos="fade-up">
                    <img src="../assets/img/Hospitality/Vector.png" class="img-fluid" alt="" srcset="">
                </div>
            </div>
        </div>
     </section>

    </div>
</template>

<script lang="ts" setup>
</script>

<style scroped>
.responsive {
    max-width: 100%;
    height: auto;
}

</style>