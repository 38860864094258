<template>
  <div>
    <!-- Banner Section -->
     <section class="banner d-flex align-items-center justify-content-center cleartx">
        <div class="container-fluid">
            <img src="../assets/img/About/homesection.webp" class="img" alt="csr">
            <div class="container csrbanner">
                <h2 class="csrbannertitle" data-aos="fade-up">About Us</h2>
            </div>
        </div>
    </section>

    <section class="mt-4 aboutbgline">
        <div class="container">
            <div class="row">           
                <div class="col-sm-7 mt-5" data-aos="fade-up">
                    <br>
                    <h3 class="text-uppercase title indexheader breadcrumbs maintitle">/  OUR STORY<span class="green-dot"></span> </h3>
                    <br>
                    <h2 class="aboutTitle">
                        The Arete Group: <br>
                        A Legacy of Sustained Growth
                    </h2>
                </div>

                <div class="col-sm-5 p-3 mt-5 " data-aos="fade-up">
                    <p class="aboutdescription">
                        Since our inception in 1990, The Arete Group has embarked on a relentless journey characterized
                        by sustained growth and strategic diversification across a multitude of sectors. </p>

                        <p class="aboutdescription">
                        Our foundation is built on unwavering values that have guided our trajectory, showcasing
                        resilience and purposeful expansion.
                    </p>
                </div>

            </div>
            <div class="row mt-5 justify-content-center" data-aos="fade-up">
                <div class="col-sm-12 p-0">
                    <img src="../assets/img/About/About_1.webp" alt="Group of people promoting mental health" class="img-fluid w-100">
                </div>
            </div>
        </div>
    </section>
 
    <section class="cleartx bganimationcsr">
        <div class="container-fluid ">
            <div class="container">
            <div class="row"> 
                <div class="col-sm-7 col align-self-center p-3 p-md-0" >
                <h2 class="csrtitle-second" data-aos="fade-up">Our Values</h2><br>
                <p class="csrdescription" data-aos="fade-up">At the core of our existence, our values are not just principles; they are the compass that directs our actions. From developing industrial parks to engaging in real estate ventures, and from commercial enterprises to upscale hospitality projects, our offerings exemplify excellence and innovation.</p>
                </div>
                <div class="col-sm-1"></div>
                <div class="col-sm-4 p-2 row" data-aos="fade-right">
                    <img src="../assets/img/About/Vector.webp" alt="img-fluid" srcset="" data-aos="fade-up">
                </div>
            </div>
            </div>
        </div>
    </section>

    <section class="cleartx">
        <div class="container">
            <div class="row d-flex flex-column-reverse-mobile">
                <div class="col-sm-5 d-flex align-items-end mb-5" data-aos="fade-up">
                    <img src="../assets/img/About/about_6.webp" alt="Group of people promoting mental health" data-aos="fade-up" class="img-fluid ">
                </div>
                <div class="col-sm-7 align-self-center" data-aos="fade-up">
                        <h2 class="csrtitle-second" data-aos="fade-up">Commitment to Stakeholders</h2><br>
                        <p class="csrdescription" data-aos="fade-up">Our values drive every decision we make, reflecting our unwavering commitment to long-term strategic foresight and impactful outcomes. We believe in creating value that extends beyond self-interest, prioritizing the holistic well-being of all our stakeholders.</p>
                </div>
            </div>
        </div>
    </section>

    <section class="cleartx aboutbgimg">
        <div class="container">
            <div class="row">
                
                <div class="col-sm-6 align-self-center p-sm-0" >

                        <h2 class="csrtitle-second" data-aos="fade-up">Expertise and Distinction</h2><br>
                        <p class="csrdescription" data-aos="fade-up">Each initiative we undertake is meticulously crafted to demonstrate the depth of our expertise,
                            thereby ensuring a mark of distinction in all our endeavours. Upheld by the pillars of integrity, transparency, and unwavering honesty, we navigate the business landscape with a steadfast
                            commitment to ethical conduct.</p>
                </div>
                <div class="col-sm-1"></div>
                <div class="col-sm-5 d-flex justify-content-center align-items-end" style="gap: 10px;" >
                    <img src="../assets/img/About/About_5.jpg"  alt="Group of people promoting mental health" class="img-fluid responsive" data-aos="fade-up">
                    <img src="../assets/img/About/About_3.jpg" alt="Group of people promoting mental health" class="img-fluid responsive" data-aos="fade-left">
                    <img src="../assets/img/About/About_4.jpg" alt="Group of people promoting mental health" class="img-fluid responsive" data-aos="fade-right">
                </div>
            </div>
        </div>
    </section>

    <section class="cleartx connectBgvector3">
        <div class="container">
            <div class="row">
                <div class="col-md-6 mb-4 mt-5">
                    <div class="industrialHeading">
                       
                        <h2 class="industrialTitle-connect" >Looking Ahead</h2>
                        <br>
                        <p class="industrialdescription text-white" data-aos="fade-up">As we look to the future, our values will continue to serve as catalysts for growth, propelling us forward as we inscribe new chapters of triumph and prosperity. </p>
                        <p class="industrialdescription text-white" data-aos="fade-up">Join us on this journey as we strive to make a meaningful impact in every sector we touch.</p>
                        <br>
                        <router-link to="/Contactus">
                            <a  id="brand-link" data-aos="fade-up" class="view-website-btn text-decoration-none mt-3 border-white text-white" >Contact Us</a>
                        </router-link>
                    </div>
                  </div>
                  <div class="col-sm-6  d-flex justify-content-end p-2">
                    <img src="../assets/img/About/lookinghead.webp" class="img-fluid" alt="industialpark" data-aos="fade-up" srcset="">
                </div>
            </div>
        </div>
     </section>
    
    
  </div>
</template>

<script lang="ts" setup>

</script>

<style scroped>
 .responsive {
  max-width: 100%;
  height: auto;
}
</style>